import React from "react";
import { Grid } from "@mui/material";
import CategoryItem from "./category-item";
import { navigateTo, setParams, getParams } from "../../utils/location";

const CategoriesView = (props) => {
  const { menuData, location, ...rest } = props;

  const handleCategoryClick = (category) => {
    navigateTo(
      setParams("/order", {
        ...getParams(location),
        toCategoryName: category.name,
        toCategoryId: category.id,
      })
    );
  };

  return (
    <Grid container spacing={2} sx={{ mt: "60px", p: "16px" }}>
      {menuData.map((category) => (
        <CategoryItem
          category={category}
          onClick={() => handleCategoryClick(category)}
          {...rest}
        />
      ))}
    </Grid>
  );
};

export default CategoriesView;
